import { render, staticRenderFns } from "./HighchartSunburst.vue?vue&type=template&id=3b45a378&"
import script from "./HighchartSunburst.vue?vue&type=script&lang=js&"
export * from "./HighchartSunburst.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports