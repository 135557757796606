<template>
  <div class="site-workcenter-widget-group">
    <b-row>
      <b-col>
        <h4 style="margin-bottom: 20px">{{ $t('Site Workcenters') }}</h4>
        <slot> </slot>
        <draggable
          class="draggable-list row"
          :disabled="!isEditing"
          group="group-widget-plant-line"
        >
          <b-col
            lg="2"
            md="4"
            sm="6"
            cols="6"
            v-for="data in plantLineWidgetsData.data"
            :key="JSON.stringify(data)"
          >
            <Widget style="height: 96%" :widgetData="data" :key="data.name" />
          </b-col>
        </draggable>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import useGroupPlantLine from "../composables/GroupSiteWorkcenter";
import NumericWidgetGradient from "../widgets/numeric-widgets/NumericStatus.vue";
import { BRow, BCol } from "bootstrap-vue";
import { ref, inject, watch } from "@vue/composition-api";
import Widget from "../widgets/Widget.vue";
import ErrorNoData from "@/views/miscellaneous/ErrorNoData.vue";
import LoadingScreen from "@/views/pamis/components/LoadingScreen.vue";
//dispatch plant data
export default {
  components: {
    draggable,
    BRow,
    BCol,
    NumericWidgetGradient,
    Widget,
    ErrorNoData,
    LoadingScreen,
  },
  setup(props, { emit }) {
    const isEditing = inject("isEditing");
    const {
      plantLineWidgetsData,
      hasGroupSiteWorkcenterData,
      checkGroupStatus,
    } = useGroupPlantLine(props);
    emit("GroupStatusChange", checkGroupStatus.value);
    watch(checkGroupStatus, () => {
      emit("GroupStatusChange", checkGroupStatus.value);
    });
    return {
      isEditing,
      plantLineWidgetsData,
      checkGroupStatus,
    };
  },
};
//there will be multiple instances of normal bars
</script>
<style scoped>
.site-workcenter-widget-group {
  border-bottom: 1px solid #ebedf3;
  margin-bottom: 10px;
}
</style>
