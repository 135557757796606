<template>
  <div>
    <div v-if="sites.length > 0">
      <b-tabs content-class="mt-2" v-model="tabIndex">
        <b-tab
          lazy
          v-for="site in sites"
          :key="site.id"
          :title="site.name"
          :id="`country-${site.name}-tab`"
        >
          <div class="container-fluid">
            <country-site-tab :site="site" />
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import CountrySiteTab from "./SectionCountrySiteTab.vue";
import {
  computed,
  inject,
  ref,
  watch,
  onMounted,
  watchEffect,
} from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import { BTabs, BTab } from "bootstrap-vue";
import useExtensionalBreadCrumb from "@/@core/layouts/components/app-bread-crumb/useExtensionalBreadCrumb";
export default {
  components: {
    BTabs,
    BTab,
    CountrySiteTab,
  },
  setup() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    //const appRole = userData ? userData.roles[0] : "OPERATOR";
    const appRole = "OPERATOR";
    const currentRoute = ref(router.currentRoute);
    const tabIndex = ref(Number(currentRoute.value.params.tab));
    const { name: countryName } = inject("selectedCountry");
    onMounted(() => {});
    watch(tabIndex, (newVal) => {
      router.push({
        name: currentRoute.name,
        params: { ...currentRoute.params, tab: newVal },
      });
    });
    const sites = computed(() => {
      let result = store.getters["pamis/GetSitesByCountryId"];

      //result = ((appRole === 'CEO') ? result : result.filter(x=>x.name === 'Plastics Plant'))
      return result;
    });
    const breadCrumb = ref([
      {
        text: countryName,
        active: true,
      },
    ]);
    watch(sites, () => {
      if (sites.value.length > 0) {
        breadCrumb.value.push({
          text: sites.value[tabIndex.value].name,
          active: true,
        });
      }
    });
    useExtensionalBreadCrumb(breadCrumb);

    return {
      tabIndex,
      sites,
      appRole,
    };
  },
};
</script>
<style></style>
