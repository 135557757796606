<template>
  <div class="site-workcenter-chart-group">
    <!--
    <b-button @click="testData">Test me</b-button>
    -->
    <h4 style="margin-bottom: 20px">{{ $t('Site Workcenters Production') }}</h4>

    <slot> </slot>
    <b-row>
      <b-col>
        <draggable
          v-show="checkGroupStatus == 'success'"
          class="draggable-list row"
          group="group-chart-plant-line"
          :disabled="!isEditing"
        >
          <b-col cols="12" lg="6" xl="6">
            <Widget
              :widgetData="finalProductionQuantityOptions"
              :key="JSON.stringify(finalProductionQuantityOptions)"
            />
          </b-col>
          <b-col cols="12" lg="6" xl="6">
            <Widget
              :widgetData="allWorkcentersPPMOptions"
              :key="JSON.stringify(allWorkcentersPPMOptions)"
            />
          </b-col>
          <b-col cols="12" lg="5" xl="5">
            <Widget
              :widgetData="finalBreakdownRateOptions"
              :key="JSON.stringify(finalBreakdownRateOptions)"
            />
          </b-col>
          <b-col cols="12" lg="7" xl="7">
            <Widget
              :widgetData="finalKpiOptions"
              :key="JSON.stringify(finalKpiOptions)"
            />
          </b-col>
          <b-col cols="12" lg="12" xl="12">
            <Widget
              :widgetData="breakdownPieOptions"
              :key="JSON.stringify(breakdownPieOptions)"
            />
          </b-col>
        </draggable>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import useGroupChart from "../composables/GroupChart";
import HighchartColumn from "@/views/pamis/components/widgets/chart-widgets/HighchartColumn.vue";
import HighchartSunburst from "../widgets/chart-widgets/HighchartSunburst.vue";
import { BRow, BCol, BButton } from "bootstrap-vue";
import store from "@/store";
import Widget from "../widgets/Widget.vue";
import ErrorNoData from "@/views/miscellaneous/ErrorNoData.vue";
import LoadingScreen from "@/views/pamis/components/LoadingScreen.vue";
import { ref, inject, watch } from "@vue/composition-api";
//dispatch plant data
export default {
  components: {
    BRow,
    BCol,
    HighchartSunburst,
    HighchartColumn,
    BButton,
    draggable,
    Widget,
    ErrorNoData,
    LoadingScreen,
  },
  setup(props, { emit }) {
    const isEditing = inject("isEditing");
    const key = ref(0);
    const {
      finalProductionQuantityOptions,
      allWorkcentersPPMOptions,
      finalBreakdownRateOptions,
      finalKpiOptions,
      checkGroupStatus,
      breakdownPieOptions,
    } = useGroupChart();
    emit("GroupStatusChange", checkGroupStatus.value);
    watch(checkGroupStatus, () => {
      emit("GroupStatusChange", checkGroupStatus.value);
    });

    return {
      breakdownPieOptions,
      isEditing,
      finalProductionQuantityOptions,
      allWorkcentersPPMOptions,
      finalBreakdownRateOptions,
      finalKpiOptions,
      checkGroupStatus,
      key,
    };
  },
};
//there will be multiple instances of normal bars
</script>
<style scoped>
.site-workcenter-chart-group {
  border-bottom: 1px solid #ebedf3;
  margin-bottom: 10px;
}
</style>
