<template>
  <div>
    <draggable
      class="draggable-list"
      group="site-groups"
      :disabled="!isEditing"
      v-model="groups"
    >
      <div v-for="group in groups" :key="group.name">
        <Group :groupName="group.name" :key="group.name" />
      </div>
    </draggable>
  </div>
</template>
<script>
import useProductionData from "../components/composables/useProductionData";
import draggable from "vuedraggable";
import Group from "../components/groups/Group.vue";
import store from "@/store";
import { BCol, BRow } from "bootstrap-vue";
import {
  inject,
  ref,
  watch,
  toRefs,
  computed,
  onBeforeUnmount,
} from "@vue/composition-api";
export default {
  components: {
    BRow,
    BCol,
    draggable,
    Group,
  },
  props: {
    groupName: null,
    site: Object,
  },
  setup(props) {
    const siteInfo = toRefs(props.site);
    const isInitData = ref(true);
    const isEditing = inject("isEditing");
    const groupList = ref([]);
    useProductionData(siteInfo);


    const groups = ref([]);
    if (window.localStorage.getItem("country-groups") == null) {
      groups.value.push({
        name: "GroupSiteWidget",
        order: 1,
        fixed: false,
      });
      groups.value.push({
        name: "GroupSiteWorkcenterWidget",
        order: 2,
        fixed: false,
      });
      groups.value.push({
        name: "GroupSiteWorkcenterChart",
        order: 3,
        fixed: false,
      });
      isInitData.value = false;
    } else {
      groups.value = JSON.parse(window.localStorage.getItem("country-groups"));
    }
    watch(groups, (newData) => {
      if (!isInitData.value)
        window.localStorage.setItem("country-groups", JSON.stringify(newData));
    });
    return {
      isEditing,
      groups,
    };
  },
};
</script>

<style></style>
