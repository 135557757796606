import {
  computed,
  ref,
  watch,
  onBeforeUnmount,
} from "@vue/composition-api";

import store from "@/store";
export default function setupProductionData(siteInfo) {
  const interval = ref(null);
  const datefilterId = computed(() => {
    return store.getters["pamis-country-module/GetDateFilterId"];
  });
  const siteId = computed(() => {
    return store.getters["pamis-country-module/GetSiteId"];
  });

  store.dispatch("pamis-country-module/SetSiteId", siteInfo.id.value);

  async function refreshData() {
    await Promise.allSettled([
      store.dispatch("pamis-country-module/GetSiteSummaryInfo", {
        siteId: siteId.value,
        dateFilterId: datefilterId.value,
      }),
      store.dispatch("pamis-country-module/GetSiteBreakdownRate", {
        siteId: siteId.value,
        dateFilterId: datefilterId.value,
      }),
      store.dispatch("pamis-country-module/GetSiteBreakdown", {
        siteId: siteId.value,
        dateFilterId: datefilterId.value,
      }),
      store.dispatch("pamis-country-module/GetSiteWorkcenterProductionData", {
        siteId: siteId.value,
        dateFilterId: datefilterId.value,
      }),
    ]);
  }

  refreshData();
  interval.value = setInterval(() => {
    refreshData();
  }, 120000);

  onBeforeUnmount(() => {
    clearInterval(interval.value);
  });

  watch([datefilterId], () => {
    refreshData();
  });

  return {};
}
