<template>
  <div>
    <a  class="gradient-widget">
      <b-card @click="GoToWorkcenter(data)" no-body>
        <b-card-body
          style="height: clamp(80px,12vw,140px)"
          :class="`${data.statusColor}-background`"
        >
          <h3 class="m-b-0 body-header" style="padding-top: 10px;">
            {{ $t(data.name) }}
          </h3>
        </b-card-body>
        <b-card-footer style="padding: 5px 15px 5px 15px;" >
          <b-row>
            <b-col class="col-6">
              <h6 class="text-muted m-b-10 footer-header">
                {{ $t(data.topLeftHeader) }}
              </h6>
              <h5 class="m-b-0 f-w-600 footer-value"  :id="`${$t(data.topLeftHeader)}_value`">
                {{ leftCount }}
              </h5>
            </b-col>
            <b-col class="col-6" style="border-left: 1px solid #ebedf3">
              <h6 class="text-muted m-b-10 footer-header">
                {{ $t(data.topRightHeader) }}
              </h6>
              <h5 class="m-b-0 f-w-600 footer-value" :id="`${$t(data.topRightHeader)}_value`">
                {{ rightCount }}
              </h5>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-card></a
    >
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BRow,
  BCol,
} from "bootstrap-vue";
import router from "@/router";
import anime from "animejs";
import {ref,toRefs,watch} from "@vue/composition-api"
export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardFooter,
    BRow,
    BCol,
  },
  props: {
    data: {
      dir: null,
      name: null,
      topLeftHeader: null,
      topRightHeader: null,
      topLeftValue: null,
      topRightValue: null,
      bottomLeftHeader: null,
      bottomRightHeader: null,
      bottomValue: null,
      statusColor: "gray",
    },
  },
  setup(props){
    const {data} = toRefs(props)
    const leftCount = ref(0)
    const rightCount= ref(0)
    setCount(data.value.topLeftValue,data.value.topRightValue)
    watch(data,(newData)=>{
    setCount(data.value.topLeftValue,data.value.topRightValue)

      })

      function setCount(newleftVal,newrightVal){

      const objLeft = {n:leftCount.value}
      anime({
        targets: objLeft,
        n: newleftVal,
        round: 1,
        duration: 1000,
        easing: 'linear',
        update: () => {
          leftCount.value = objLeft.n
        }
      })

      const objRight = {n:rightCount.value}
      anime({
        targets: objRight,
        n: newrightVal,
        round: 1,
        duration: 1000,
        easing: 'linear',
        update: () => {
          rightCount.value = objRight.n
        }
      })

     
    }
    const GoToWorkcenter = (data)=>{
      
        router.push(`/workcenter/${data.name}/id=${data.dir}`)
      
      }
    return {
      GoToWorkcenter,
      leftCount,
      rightCount
    }

  }
};
</script>
<style src="./assets/scss/widget-colors.scss" scoped lang="scss" scoped></style>
<style scoped>
.body-header {
  text-align: center;
  font-size: clamp(1.0em,2.5vw,1.5em);
}
.footer-header {
  font-size: clamp(0.7em,2.0vw,1.0em);
  text-align: center;
}
.footer-value {
  text-align: center;
  font-size: clamp(0.75em,2.0vw,1.0em);
}
</style>
