<template>
  <div class="site-widget-group">
    <b-row>
      <b-col>
        <h4 style="margin-bottom: 20px">{{ $t('Site Info') }}</h4>
        <slot> </slot>
        <draggable
          v-if="checkGroupStatus === 'success'"
          class="draggable-list row"
          group="group-widget-site"
          :disabled="!isEditing"
        >
          <b-col
            lg="3"
            cols="6"
            v-for="data in [
              ...siteWidgetsData.data,
              ...siteTargetWidgetsData.data,
            ]"
            :key="JSON.stringify(data.header)"
          >
            <Widget :widgetData="data" />
          </b-col>
        </draggable>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import useGroupSite from "../composables/GroupSite";
import NumericWidgetTarget from "../widgets/numeric-widgets/NumericTarget.vue";
import NumericWidget from "../widgets/numeric-widgets/NumericNormal.vue";
import NumericWidgetGradient from "../widgets/numeric-widgets/NumericStatus.vue";
import { BRow, BCol } from "bootstrap-vue";
import ErrorNoData from "@/views/miscellaneous/ErrorNoData.vue";
import { inject, watch, ref } from "@vue/composition-api";
import LoadingScreen from "@/views/pamis/components/LoadingScreen.vue";
import Widget from "../widgets/Widget.vue";
//dispatch plant data
export default {
  components: {
    BRow,
    BCol,
    NumericWidgetTarget,
    NumericWidget,
    NumericWidgetGradient,
    draggable,
    Widget,
    ErrorNoData,
    LoadingScreen,
  },
  setup(props, { emit }) {
    const isEditing = inject("isEditing");
    const { siteWidgetsData, siteTargetWidgetsData, checkGroupStatus } =
      useGroupSite(props);
    emit("GroupStatusChange", checkGroupStatus.value);
    watch(checkGroupStatus, () => {
      emit("GroupStatusChange", checkGroupStatus.value);
    });

    return {
      isEditing,
      siteWidgetsData,
      siteTargetWidgetsData,
      checkGroupStatus,
    };
  },
};
//there will be multiple instances of normal bars
</script>
<style scoped>
.site-widget-group {
  border-bottom: 1px solid #ebedf3;
  margin-bottom: 10px;
}
</style>
