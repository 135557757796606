import ApiService from "@/services/pamisDashboardApi.service";
import mockdatasitegetdatefilters from "@/mockdata/sitegetdatefilters.json"
import mockdatasitesummaryinfo from "@/mockdata/sitesummaryinfo.json"
import mockdatasitegetbreakdownrate from "@/mockdata/sitegetbreakdownrate.json"
import mockdatasitesitegetbreakdown from "@/mockdata/sitegetbreakdown.json"
import mockdatasiteproductionquantity from "@/mockdata/siteproductionquantity.json"
import i18n from '@/libs/i18n'

const getDefaultState = () => {
  return {
    siteSummaryInfo: null,
    siteWorkcenterProductionData: null,
    dateFilters: null,
    dateFilterId: 2,
    siteId: null,
    breakdownRate: null,
    breakdown: null,
  };
};
const CancelAllRequests = () => {
  ApiService.abortRequests();
};
const ResetProdStates = (state) => {
  state.siteSummaryInfo = null;
  state.siteWorkcenterProductionData = null;
  state.breakdownRate = null;
  state.breakdown = null;
};
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    GetBreakdownRate(state) {
      return state.breakdownRate;
    },
    GetBreakdown(state) {
      return state.breakdown;
    },
    GetSiteSummaryInfo(state) {
      return state.siteSummaryInfo;
    },
    GetSiteId(state) {
      return state.siteId;
    },
    GetDateFilters(state) {
      return state.dateFilters;
    },
    GetDateFilterId(state) {
      return state.dateFilterId;
    },
    GetSiteWorkcenterProductionData(state) {
      return state.siteWorkcenterProductionData;
    },
  },
  actions: {
    GetSiteSummaryInfo(ctx, param) {
      // let data = mockdatasitesummaryinfo;
      // var production = data.datasource.find(x => x.id == 1);
      // var defect = data.datasource.find(x => x.id == 2);
      // var ppm = data.datasource.find(x => x.id == 3);
      // var breakdownRate = data.datasource.find(x => x.id == 4);
      // var oee = data.datasource.find(x => x.id == 5);
      // var availability = data.datasource.find(x => x.id == 6);
      // var performance = data.datasource.find(x => x.id == 7);
      // var quality = data.datasource.find(x => x.id == 8);

      // if (param.dateFilterId == 1) {
      //   production.value = 26
      //   defect.value = 2
      //   ppm.value = (defect.value / production.value) * 1000000
      //   breakdownRate.value = 12
      //   availability.value = 85
      //   availability.target = 80
      //   performance.value = 92
      //   performance.target = 80
      //   quality.value = 85
      //   quality.target = 85
      //   oee.value = 80
      //   oee.target = 82
      // } else if (param.dateFilterId == 2) {
      //   production.value = 516
      //   defect.value = 27
      //   ppm.value = (defect.value / production.value) * 1000000
      //   breakdownRate.value = 7
      //   availability.value = 90
      //   availability.target = 80
      //   performance.value = 95
      //   performance.target = 80
      //   quality.value = 90
      //   quality.target = 85
      //   oee.value = 81
      //   oee.target = 82
      // } else if (param.dateFilterId == 3) {
      //   production.value = 3816
      //   defect.value = 182
      //   ppm.value = (defect.value / production.value) * 1000000
      //   breakdownRate.value = 8
      //   availability.value = 88
      //   availability.target = 80
      //   performance.value = 91
      //   performance.target = 80
      //   quality.value = 87
      //   quality.target = 85
      //   oee.value = 85
      //   oee.target = 82
      // } else if (param.dateFilterId == 4) {
      //   production.value = 15684
      //   defect.value = 742
      //   ppm.value = (defect.value / production.value) * 1000000
      //   breakdownRate.value = 15
      //   availability.value = 90
      //   availability.target = 80
      //   performance.value = 95
      //   performance.target = 80
      //   quality.value = 90
      //   quality.target = 85
      //   oee.value = 82
      //   oee.target = 80
      // } else if (param.dateFilterId == 10) {
      //   production.value = 508
      //   defect.value = 21
      //   ppm.value = (defect.value / production.value) * 1000000
      //   breakdownRate.value = 13
      //   availability.value = 92
      //   availability.target = 80
      //   performance.value = 96
      //   performance.target = 80
      //   quality.value = 94
      //   quality.target = 85
      //   oee.value = 80
      //   oee.target = 80
      // } else if (param.dateFilterId == 11) {
      //   production.value = 3946
      //   defect.value = 195
      //   ppm.value = (defect.value / production.value) * 1000000
      //   breakdownRate.value = 10
      //   availability.value = 86
      //   availability.target = 80
      //   performance.value = 93
      //   performance.target = 80
      //   quality.value = 92
      //   quality.target = 85
      //   oee.value = 80
      //   oee.target = 80
      // } else if (param.dateFilterId == 12) {
      //   production.value = 16875
      //   defect.value = 532
      //   ppm.value = (defect.value / production.value) * 1000000
      //   breakdownRate.value = 9
      //   availability.value = 94
      //   availability.target = 80
      //   performance.value = 94
      //   performance.target = 80
      //   quality.value = 93
      //   quality.target = 85
      //   oee.value = 82
      //   oee.target = 78
      // }


      //ctx.commit("SetSiteSummaryInfo", { ...data, context: 'Site Info' });
      return new Promise((resolve, reject) => {
        ApiService.query("ProductionData/Site/SummaryInfo", {
          siteId: param.siteId,
          dateFilterId: param.dateFilterId,
        })
          .then((response) => {
            ctx.commit("SetSiteSummaryInfo", { ...response.data, context: 'Site Info' });
            resolve(response.data);
          })
          .catch((error) => {
            ctx.commit("SetSiteSummaryInfo", {
              isError: true,
              context: error,
            });
            reject(error);
          });
      });
    },
    SetSiteId(ctx, siteId) {
      ctx.commit("SetSiteId", siteId);
    },
    GetSiteBreakdownRate(ctx, param) {
      // let data;
      // if (param.siteId == 100)
      //   data = mockdatasitegetbreakdownrate.plastic;
      // else
      //   data = mockdatasitegetbreakdownrate.ic;
      // ctx.commit("SetSiteBreakdownRate", { ...data, context: 'Site Breakdown' });
      return new Promise((resolve, reject) => {
        ApiService.query("Breakdown/Site/GetBreakdownRate", {
          siteId: param.siteId,
          dateFilterId: param.dateFilterId,
        })
          .then((response) => {
            ctx.commit("SetSiteBreakdownRate", { ...response.data, context: 'Site Breakdown' });
            resolve(response.data);
          })
          .catch((error) => {
            ctx.commit("SetSiteBreakdownRate", {
              isError: true,
              context: error,
            });
            reject(error);
          });
      });
    },
    GetSiteBreakdown(ctx, param) {
      // let data;
      // if (param.siteId == 100)
      //   data = mockdatasitesitegetbreakdown.plastic;
      // else
      //   data = mockdatasitesitegetbreakdown.ic;
      // ctx.commit("SetSiteBreakdown", { ...data, context: 'Site Breakdown' });
      return new Promise((resolve, reject) => {
        ApiService.query("Breakdown/Site/GetBreakdown", {
          siteId: param.siteId,
          dateFilterId: param.dateFilterId,
        })
          .then((response) => {
            ctx.commit("SetSiteBreakdown", { ...response.data, context: 'Site Breakdown' });
            resolve(response.data);
          })
          .catch((error) => {
            ctx.commit("SetSiteBreakdown", {
              isError: true,
              context: error,
            });
            reject(error);
          });
      });
    },
    GetSiteWorkcenterProductionData(ctx, param) {

      // let data;
      // if (param.siteId == 100)
      //   data = mockdatasiteproductionquantity.plastic;
      // else
      //   data = mockdatasiteproductionquantity.ic;

      // var pt7 = data.datasource.find(x => x.name == "L201");
      // var pt9 = data.datasource.find(x => x.name == "L202");
      // var pt11 = data.datasource.find(x => x.name == "L203");
      // var pt12 = data.datasource.find(x => x.name == "L204");
      // var pt13 = data.datasource.find(x => x.name == "L205");
      // var l101 = data.datasource.find(x => x.name == "L101");
      // var l102 = data.datasource.find(x => x.name == "L102");

      // if (param.dateFilterId == 1) {
      //   if (pt7 != undefined) {
      //     pt7.ppm = 31326
      //     pt7.oee = 80
      //     pt9.ppm = 15659
      //     pt9.oee = 68
      //     pt11.ppm = 28326
      //     pt11.oee = 81
      //     pt12.ppm = 0
      //     pt12.oee = 0
      //     pt13.ppm = 32346
      //     pt13.oee = 79
      //   }

      //   if (l101 != undefined) {
      //     l101.ppm = 36324
      //     l101.oee = 85
      //     l102.ppm = 25668
      //     l102.oee = 76
      //   }
      // } else if (param.dateFilterId == 2) {
      //   if (pt7 != undefined) {
      //     pt7.ppm = 24326
      //     pt7.oee = 82
      //     pt9.ppm = 28659
      //     pt9.oee = 65
      //     pt11.ppm = 24436
      //     pt11.oee = 82
      //     pt12.ppm = 0
      //     pt12.oee = 0
      //     pt13.ppm = 22118
      //     pt13.oee = 78
      //   }

      //   if (l101 != undefined) {
      //     l101.ppm = 24467
      //     l101.oee = 90
      //     l102.ppm = 28744
      //     l102.oee = 78
      //   }
      // } else if (param.dateFilterId == 3) {
      //   if (pt7 != undefined) {
      //     pt7.ppm = 21226
      //     pt7.oee = 85
      //     pt9.ppm = 19659
      //     pt9.oee = 60
      //     pt11.ppm = 24436
      //     pt11.oee = 85
      //     pt12.ppm = 0
      //     pt12.oee = 0
      //     pt13.ppm = 26118
      //     pt13.oee = 79
      //   }

      //   if (l101 != undefined) {
      //     l101.ppm = 29443
      //     l101.oee = 86
      //     l102.ppm = 26112
      //     l102.oee = 66
      //   }
      // } else if (param.dateFilterId == 4) {
      //   if (pt7 != undefined) {
      //     pt7.ppm = 18224
      //     pt7.oee = 86
      //     pt9.ppm = 25651
      //     pt9.oee = 69
      //     pt11.ppm = 28432
      //     pt11.oee = 82
      //     pt12.ppm = 0
      //     pt12.oee = 0
      //     pt13.ppm = 21122
      //     pt13.oee = 72
      //   }

      //   if (l101 != undefined) {
      //     l101.ppm = 32441
      //     l101.oee = 85
      //     l102.ppm = 19112
      //     l102.oee = 65
      //   }
      // } else if (param.dateFilterId == 10) {
      //   if (pt7 != undefined) {
      //     pt7.ppm = 35234
      //     pt7.oee = 81
      //     pt9.ppm = 28616
      //     pt9.oee = 67
      //     pt11.ppm = 24343
      //     pt11.oee = 86
      //     pt12.ppm = 0
      //     pt12.oee = 0
      //     pt13.ppm = 21118
      //     pt13.oee = 78
      //   }

      //   if (l101 != undefined) {
      //     l101.ppm = 25449
      //     l101.oee = 88
      //     l102.ppm = 32112
      //     l102.oee = 68
      //   }
      // } else if (param.dateFilterId == 11) {
      //   if (pt7 != undefined) {
      //     pt7.ppm = 21226
      //     pt7.oee = 80
      //     pt9.ppm = 19659
      //     pt9.oee = 65
      //     pt11.ppm = 23436
      //     pt11.oee = 88
      //     pt12.ppm = 0
      //     pt12.oee = 0
      //     pt13.ppm = 26118
      //     pt13.oee = 78
      //   }

      //   if (l101 != undefined) {
      //     l101.ppm = 28445
      //     l101.oee = 90
      //     l102.ppm = 29112
      //     l102.oee = 82
      //   }
      // } else if (param.dateFilterId == 12) {
      //   if (pt7 != undefined) {
      //     pt7.ppm = 21226
      //     pt7.oee = 83
      //     pt9.ppm = 19659
      //     pt9.oee = 62
      //     pt11.ppm = 24436
      //     pt11.oee = 80
      //     pt12.ppm = 0
      //     pt12.oee = 0
      //     pt13.ppm = 26118
      //     pt13.oee = 79
      //   }

      //   if (l101 != undefined) {
      //     l101.ppm = 25447
      //     l101.oee = 90
      //     l102.ppm = 32112
      //     l102.oee = 68
      //   }
      // }

      // ctx.commit("SetSiteWorkcenterProductionData", { ...data, context: 'workcenters production info' });
      return new Promise((resolve, reject) => {
        ApiService.query("ProductionData/Site/ProductionQuantity", {
          siteId: param.siteId,
          dateFilterId: param.dateFilterId,
        })
          .then((response) => {
            ctx.commit("SetSiteWorkcenterProductionData", { ...response.data, context: 'workcenters production info' });
            resolve(response.data);
          })
          .catch((error) => {
            ctx.commit("SetSiteWorkcenterProductionData", {
              isError: true,
              context: error,
            });
            reject(error);
          });
      });
    },
    GetDateFilters(ctx) {
      return new Promise((resolve, reject) => {
        ApiService.get("Utility/Site", "GetDateFilters")
          .then((response) => {
            ctx.commit("SetDateFilters", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            ctx.commit("SetDateFilters", null);
            reject(error);
          });
      });
    },
    SetDateFilterId(ctx, newId) {
      ctx.commit("SetDateFilterId", newId);
    },
    RESET_STATE(ctx) {
      ctx.commit("SET_RESET_STATE");
    },
  },
  mutations: {
    SET_RESET_STATE(state) {
      CancelAllRequests();
      Object.assign(state, getDefaultState());
    },
    SetSiteBreakdownRate(state, breakdownRate) {
      state.breakdownRate = breakdownRate;
    },
    SetSiteBreakdown(state, breakdown) {
      state.breakdown = breakdown;
    },
    SetSiteSummaryInfo(state, summaryInfo) {
      state.siteSummaryInfo = summaryInfo;
    },
    SetDateFilters(state, filters) {
      state.dateFilters = filters;
    },
    SetDateFilterId(state, newDateFilterId) {
      CancelAllRequests();
      ResetProdStates(state);
      state.dateFilterId = newDateFilterId;
    },
    SetSiteId(state, siteId) {
      state.siteId = siteId;
    },
    SetSiteWorkcenterProductionData(state, productionQuantity) {
      state.siteWorkcenterProductionData = productionQuantity;
    },
  },
};
