import { computed } from "@vue/composition-api";
import store from "@/store";
import { GetDataStatus, GetGroupStatus } from "@/utils/utils";
export default function getPlantLineWidgetGroup() {
  const plantLineWidgetsData = computed(() => {
    let data =
      store.getters["pamis-country-module/GetSiteWorkcenterProductionData"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status === "success") {
      let linesData = data.datasource.map((x, i = 0) => {
        i++;
        return {
          response_status: "success",
          widgetType: "NumericStatus",
          dir: x.id,
          topLeftHeader: "PPM",
          topLeftValue: x.ppm,
          topRightHeader: "OEE",
          topRightValue: x.oee,
          bottomValue: x.uptime,
          indicatorLimit: 50,
          name: x.name,
          statusColor: getColor(x),
          order: i,
          fixed: false,
          status: "",
          duration: x.uptime,
        };
      });
      return { response_status: "success", data: linesData };
    } else {
      return dataState;
    }
  });
  const getColor = (x) => {
    if (x.total < 1) {
      return "gray";
    }
    if (x.oee >= x.kpiOee) {
      return "green";
    } else if (x.oee >= x.kpiOeePre) return "orange";
    else {
      return "pink";
    }
  };
  const checkGroupStatus = computed(() => {
    const options = [plantLineWidgetsData.value.response_status];
    return GetGroupStatus(options);
  });
  return {
    plantLineWidgetsData,
    checkGroupStatus,
  };
}
