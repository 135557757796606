<template>
  <div id="controlchart">
    <b-card>
      <highcharts
        :constructor-type="'chart'"
        style="margin-left: 20px; padding-left: 0px"
        :options="options"
      />
    </b-card>
  </div>
</template>
<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import { Chart } from "highcharts-vue";
import { ChartUpdateArgs } from "highcharts-vue";
import Highcharts from "highcharts";
import sunburstInit from "highcharts/modules/sunburst";
import exporting from "highcharts/modules/exporting";
exporting(Highcharts);
sunburstInit(Highcharts);
export default {
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    Highcharts: Chart,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      options: {
        credits: {
          enabled: false,
        },
        chart: {
          height: "600px",
        },
        series: [
          {
            type: "sunburst",
            data: [
              {
                id: "1.1",
                name: "Chrome",
              },
              {
                id: "1.2",
                name: "Safari",
              },
              {
                id: "1.3",
                name: "Firefox",
              },
              {
                parent: "1.1",
                value: 36,
                name: "Chrome v97",
              },
              {
                parent: "1.1",
                value: 18,
                name: "Chrome v96",
              },
              {
                parent: "1.2",
                value: 2,
                name: "Safari v15",
              },
              {
                parent: "1.2",
                value: 2,
                name: "Safari v14",
              },
              {
                parent: "1.2",
                value: 2,
                name: "Safari v13",
              },
              {
                parent: "1.3",
                value: 4,
                name: "Firefox v96",
              },
              {
                parent: "1.3",
                value: 2,
                name: "Firefox v95",
              },
              {
                parent: "1.3",
                value: 1,
                name: "Firefox v94",
              },
            ],
            levels: [
              {
                level: 1,
                colorByPoint: true,
              },
              {
                level: 2,
                colorVariation: {
                  key: "brightness",
                  to: -0.5,
                },
              },
            ],
          },
        ],

        ...this.data,
      },
    };
  },
  methods: {
    increaseValue() {
      this.options.series[0].data[3] += 10;
      let updatedOptions = JSON.parse(JSON.stringify(this.options));
      this.options.series = updatedOptions.series;
    },
  },
};
</script>
