<template>
  <div>
    <div class="container-fluid">
      <b-card class="main-card">
        <b-row style="margin-right: 0px; margin-left: 0px">
          <b-col
            cols="4"
            lg="6"
            style="border-right: 2px solid rgba(122, 122, 122, 0.1)"
          >
            <b-card-title style="margin-bottom: 1rem">
              <h2 class="board-header">
                {{ $t(countryName) }}
              </h2>
            </b-card-title>
          </b-col>
          <b-col cols="8" lg="6">
            <date-filter class="float-right" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <SectionCountrySite />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCard,
  BCardTitle,
  BRow,
  BCol,
} from "bootstrap-vue";
import { useRouter } from "@/@core/utils/utils";
import DateFilter from "../components/DateFilter.vue";
import SectionCountrySite from "./SectionCountrySite.vue";
import countryStoreModule from "../countryStoreModule.js";
import countryStoreModule_2 from "../store/countryStoreModule";
import { onUnmounted, provide, ref } from "@vue/composition-api";
import store from "@/store";
export default {
  components: {
    SectionCountrySite,
    BButton,
    BCardBody,
    BCardFooter,
    BCardHeader,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    DateFilter,
  },
  setup() {
    const router = useRouter();
    const routerParams = router.route.value.params;
    const PAMIS_MODULE_NAME = "pamis-country";
    const PAMIS_COUNTRY_MODULE = "pamis-country-module";
    const isEditing = ref(false);
    const countryName = ref(routerParams.name);

    function resetToDefault() {
      window.localStorage.removeItem("country-groups");
      window.location.reload();
    }

    if (!store.hasModule(PAMIS_MODULE_NAME))
      store.registerModule(PAMIS_MODULE_NAME, countryStoreModule);
    onUnmounted(() => {
      if (store.hasModule(PAMIS_MODULE_NAME))
        store.unregisterModule(PAMIS_MODULE_NAME);
    });

    if (!store.hasModule(PAMIS_COUNTRY_MODULE))
      store.registerModule(PAMIS_COUNTRY_MODULE, countryStoreModule_2);
    onUnmounted(() => {
      if (store.hasModule(PAMIS_COUNTRY_MODULE))
        store.dispatch("pamis-country-module/RESET_STATE");
      store.unregisterModule(PAMIS_COUNTRY_MODULE);
    });

    store.dispatch("pamis/GetSitesByCountryId", routerParams.id);

    provide("selectedCountry", routerParams);
    provide("isEditing", isEditing);

    const changeEditingStatus = function () {
      isEditing.value = !isEditing.value;
    };
    return {
      changeEditingStatus,
      isEditing,
      countryName,
      resetToDefault,
    };
  },
};
</script>

<style scoped>
.board-header {
  font-size: clamp(20px, 3vw, 100%);
}
.button-settings,
button {
  display: flex;
  float: right;
  padding: 5px, 5px, 5px, 5px !important;
  margin: 0px;
}
.main-card {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 6px 0px;
}

.leave-active,
.enter-active {
  transition: all 0.3s;
}

.enter-from {
  opacity: 0;
}
.leave-to {
  opacity: 0;
}
</style>
