<template>
  <div>
    <b-row v-if="isEditing">
      <b-col>
        <div class="editor-bar">
          <feather-icon
            icon="MoveIcon"
            style="
              color: rgba(170, 170, 170, 0.5);
              margin: 5px;
              margin-right: 10px;
              float: right;
            "
            width="1.0vw"
            height="1.0vw"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        ><component :is="groupName" @GroupStatusChange="OnStatusChange">
          <b-row>
            <b-col>
              <WarningNoData v-if="dataStatus == 'no_data'" />
              <ErrorNoData v-else-if="dataStatus == 'error'" />
              <LoadingScreen v-else-if="dataStatus == 'pending'" />
            </b-col>
          </b-row>
        </component>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from "bootstrap-vue";
import { ref, inject } from "@vue/composition-api";
import GroupSiteWorkcenterWidget from "./GroupSiteWorkcenterWidget.vue";
import GroupSiteWidget from "./GroupSiteWidget.vue";
import GroupSiteWorkcenterChart from "./GroupSiteWorkcenterChart.vue";
import ErrorNoData from "@/views/miscellaneous/ErrorNoData.vue";
import LoadingScreen from "@/views/pamis/components/LoadingScreen.vue";
import WarningNoData from "@/views/miscellaneous/WarningNoData.vue";
export default {
  props: {
    groupName: null,
  },
  components: {
    GroupSiteWorkcenterWidget,
    GroupSiteWidget,
    GroupSiteWorkcenterChart,
    BRow,
    BCol,
    ErrorNoData,
    LoadingScreen,
    WarningNoData,
  },
  setup(props) {
    const isEditing = inject("isEditing");
    const dataStatus = ref("");
    function OnStatusChange(context) {
      dataStatus.value = context;
    }
    return {
      isEditing,
      dataStatus,
      OnStatusChange,
    };
  },
};
//there will be multiple instances of normal bars
</script>
<style scoped>
.editor-bar {
  bottom: 5px;
  height: 100%;
  width: 100%;
  background-color: rgba(240, 240, 240, 0.1);
}
</style>
