import { computed, inject, ref, watch } from "@vue/composition-api";
import { GetDataStatus, GetGroupStatus } from "@/utils/utils";
import store from "@/store";

export default function getPlantWidgetGroup() {
  const siteWidgetsData = computed(function () {
    let data = store.getters["pamis-country-module/GetSiteSummaryInfo"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status === "success") {
      let colors = ["cornflowerblue", "tomato", "BlueViolet", "peru"];
      let colorIndex = -1;
      let siteData = [];
      siteData = data.datasource
        .filter((e) => e.target == null)
        .map((x) => {
          colorIndex++;
          return {
            response_status: "success",
            widgetType: "NumericNormal",
            header: x.name,
            value: x.value,
            color: colors[colorIndex],
          };
        });
      return { response_status: "success", data:siteData };
    } else {
      return dataState;
    }
  });
  const siteTargetWidgetsData = computed(function () {
    let data = store.getters["pamis-country-module/GetSiteSummaryInfo"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status === "success") {
      let colors = ["green", "purple", "blue", "turquoise"];
      let colorIndex = -1;
      let siteData = [];
      siteData = data.datasource
        .filter((e) => e.target != null)
        .map((x) => {
          colorIndex++;
          return {
            response_status: "success",
            widgetType: "NumericTarget",
            header: x.name,
            value: "%" + x.value,
            targetValue: x.target,
            color: colors[colorIndex],
          };
        });
      return { response_status: "success", data:siteData };
    } else {
      return dataState;
    }
  });
  const checkGroupStatus = computed(() => {
    const options = [
      siteTargetWidgetsData.value.response_status,
      siteWidgetsData.value.response_status,
    ];
    return GetGroupStatus(options);
  });
  return {
    siteWidgetsData,
    siteTargetWidgetsData,
    checkGroupStatus,
  };
}
