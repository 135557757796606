<template>
  <div>
    <b-card no-body>
      <b-card-body style="padding-bottom: 10px;">
        <b-row style="margin-left: 0px">
          <!--
          <b-col cols="2">
            <feather-icon
              icon="BarChartIcon"
              :class="`${data.color}-text-color`"
              style="margin-top: 0.9vw"
              width="1.8vw"
              height="1.8vw"
            />
          </b-col>
          -->
          <b-col cols="12" style="text-align: center;padding:0px;">
            <h4 :class="`text-muted m-b-0 header-text ${data.color}-text-color` " style="text-align: center;padding:0px;">{{ $t(data.header) }}</h4>
            <h2 :class="`text-c f-w-600  value-text`" :id="`${data.header}_value`" style="text-align: center;padding:0px;">
              {{ count }}
            </h2>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer :class="`${data.color}-background card-footer`">
        <b-row>
          <b-col lg="10" md="8" sm="7" cols="8">
            <h6 class="text-white footer-text"  :id="`${data.header}_target_value`" >
              {{ `Target: %${data.targetValue}` }}
            </h6>
          </b-col>

          <b-col lg="2" md="4" sm="5" cols="4">
            <feather-icon
           
              icon="TargetIcon"
              style="color: white; float: right"
              width="clamp(1.0em,3.0vw,2.0em)"
              height="clamp(1.0em,3.0vw,2.0em)"
            />
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BRow,
  BCol,
} from "bootstrap-vue";
import anime from "animejs";
import {ref,toRefs,watch} from "@vue/composition-api"
export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardFooter,
    BRow,
    BCol,

  },
  props: {
    data: {
      color: null,
      header: null,
      value: null,
      targetValue: {
        type: Number,
      },
    },
  },
  setup(props){
    const {data} = toRefs(props)
    const count = ref(0)
    setCount(data.value.value)
    watch(data,(newData)=>{
    setCount(newData.value)

      })
      function setCount(val){

      const obj = {n:count.value}
      anime({
        targets: obj,
        n: val,
        round: 1,
        duration: 1000,
        easing: 'linear',
        update: () => {
          count.value = obj.n
        }
      })
     
    }
    return {
      count
    }

  }
};
</script>
<style src="./assets/scss/widget-colors.scss" scoped lang="scss" scoped> </style>
<style scoped>
.header-text {
  text-align: justify;
  width: 100%;
  padding:clamp(0px 0px 0px 0px,0px 0px 5px 10px,0px 0px 5px 10px);
  font-size: clamp(0.7em,2.5vw,1.3em);
}
.value-text {
  text-align: justify;
  width: 100%;
  padding:clamp(0px 0px 0px 0px, 0px 0px 5px 10px, 0px 0px 5px 10px);
  font-size: clamp(0.8em,2.5vw,1.5em);

}
.footer-text {
  margin: clamp(0px 0px 0px 0px, 0px 0px 0px 10px, 0px 0px 0px 10px);
  font-size: clamp(0.5em,2.0vw,1.2em);
}
.card-footer{
  padding-bottom: 10px;
}
</style>


